<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-link
      class="brand-logo"
      @click="scrollToTop"
    >
      <span>
        <b-img
          class="logoIndetex"
          src="@/assets/images/logo/logo.png"
          alt="logo"
        />
      </span>
    </b-link>
    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <a
        v-b-tooltip.hover.bottom="'Regresar'"
        class="text-secondary text-lighten-5"
        @click="goToHome"
      >
        <feather-icon
          size="1.7x"
          icon="ArrowLeftIcon"
        />
      </a>
      <dark-Toggler
        v-b-tooltip.hover.bottom="'Color'"
        class="d-none d-block text-secondary text-lighten-1"
      />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, VBTooltip, BLink, BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { useWindowScroll } from '@vueuse/core'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BNavbarNav,
    BImg,
    BLink,
    // Navbar Components
    DarkToggler,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { route, router } = useRouter()
    const { y } = useWindowScroll()
    const scrollToTop = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      if (window.scrollY === 0 && route.value.name !== 'home') {
        router.push('/')
      }
    }
    const goToHome = () => {
      if (route.value.name === 'home') window.open('https://www.indetec.gob.mx/biblioteca.php', '_self')
      else router.push('/')
    }

    return { y, scrollToTop, goToHome }
  },
}
</script>
<style>
.logoIndetex {
  max-width: 100px;
}
</style>
