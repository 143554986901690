<template>
  <b-row class="m-0">
    <b-col>

      <b-row class="footerc">
        <b-col
          md="12"
          class="text-center m-2"
        >
          <h4>
            Instituto para el Desarrollo Técnico de las Haciendas Públicas
          </h4>
        </b-col>
      </b-row>

      <b-row class="footerc">
        <b-col
          class="footLink"
          md="3"
        >
          <div class="text-center mx-3">
            <p>
              <b>Contacto</b>
            </p>

            <p><feather-icon
              icon="SmartphoneIcon"
              size="21"
              class="text-success stroke-current"
            /> 33-3669-5550 al 5559</p>
          </div>
        </b-col>

        <b-col md="6">
          <div class="text-center mx-1">

            <b-row class="text-center mx-2">
              <b-col>
                <p class="mx-3">
                  <feather-icon
                    icon="MapIcon"
                    size="21"
                    class="text-danger stroke-current"
                  />
                  Lerdo de Tejada No. 2469, Col. Arcos Sur. C.P. 44500
                  Guadalajara, Jalisco. México.</p>
              </b-col>
            </b-row>

            <!-- <b-row class="my-1">
              <b-col>
                <b-row class="footerc">
                  <b-col
                    class="text-center m-2"
                  >
                    <div>
                      <b-button
                        v-b-modal.modal-f2
                        variant="outline"
                        pill
                      >
                        <b>Aviso de Privacidad Integral</b>
                      </b-button>
                      <b-modal
                        id="modal-f2"
                        ref="modal-avisoprivacidad"
                        scrollable
                        title="AVISO DE PRIVACIDAD INTEGRAL - INDETEC "
                        size="lg"
                        ok-only
                        ok-title="Aceptar"
                        button-size="sm"
                      >
                        <avisoprivacidadintegral />
                      </b-modal>
                    </div></b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="footerc">
                  <b-col
                    class="text-center m-2"
                  ><b-button
                     v-b-modal.modal-f1
                     variant="outline"
                     pill
                   >
                     <b>Aviso de Privacidad Simplificado</b>
                   </b-button>
                    <b-modal
                      id="modal-f1"
                      scrollable
                      title="AVISO DE PRIVACIDAD SIMPLIFICADO - INDETEC "
                      size="lg"
                      ok-only
                      ok-title="Aceptar"
                      button-size="sm"
                    >
                      <avisoprivacidadsimplificado />
                    </b-modal>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>-->

          </div>
        </b-col>

        <b-col md="3">
          <div class="text-center mx-3">
            <p>Puedes seguirnos en</p>
            <b-link
              href="https://www.facebook.com/INDETECMX/"
              target="_blank"
            >
              <feather-icon
                class="m-1 text-success stroke-current"
                size="21"
                icon="FacebookIcon"
              />
            </b-link>
            <b-link
              href="https://www.instagram.com/indetec_mx/"
              target="_blank"
            >
              <feather-icon
                class="m-1 text-success stroke-current"
                size="21"
                icon="InstagramIcon"
              />
            </b-link>
            <b-link
              href="https://twitter.com/indetec_mx?lang=es"
              target="_blank"
            >
              <feather-icon
                class="m-1 text-success stroke-current"
                size="21"
                icon="TwitterIcon"
              />
            </b-link>
          </div>
        </b-col>
      </b-row>

      <b-row class=" mb-0 text-center copyright">
        <b-col>
          <span>
            Derechos Reservados © INDETEC  {{ new Date().getFullYear() }}
          </span>
        </b-col>
      </b-row>
      <b-row class=" mb-0 text-center copyright">
        <b-col>
          <small><b>{{ appVersion }}</b></small>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BLink, VBModal,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
// import avisoprivacidadintegral from '@core/layouts/components/footer/avisoprivacidad.vue'
// import avisoprivacidadsimplificado from './footer/avisoprivacidadsimplificado.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    // BModal,
    // BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const { appVersion } = Vue.config

    return {
      appVersion,
    }
  },
}
</script>
<style>

.footLink a{
  color: #adabab;
}
.footLink a:hover{
  color: #02e779;
}
footer.footer{
  padding: 0px !important;
}

.footerc p, .footerc h4{
  color : #adabab;
}
.footerc .btn {
color: #adabab !important;
}
.footerc .btn :hover{
color: #28c76f !important;
}

.copyright {
background-color: #1b1b1b;
background-color:#0d111d;
color: #adabab;
}
.footerc{
background-color: #2b2b2b;
background-color: #101523;
}
@media only screen and (max-width: 600px) {
  footer.footer {
    padding: 0 !important;
}}

</style>
